import FixedWidth from '../../../common/Themes/FixedWidth/Theme';
import { minimalIconMapping } from '../../../common/Components/Internal/Header/utils/mapPropsToSocialProps';
import * as filledIcons from '../../../common/IconPacks/humanisticFilled';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/minimalSocialIconPack';
import { Blink } from '../../../common/loaders';
import { ABOUT1_IMAGE_WIDTH, CONTENT2_IMAGE_WIDTH } from '../../../common/constants';
import { FILL, FIT, INSET } from '../../../common/constants/headerTreatments';
import { levelFilter } from '../../../common/utils/text';
import DataAid from '../../../common/Components/Internal/Header/constants/dataAids.js';
import { mergeTypographyOverrides } from '../../../common/utils/typography';
import themeConfig from '../themeConfig';
import { LANDSCAPE_ASPECT_RATIO } from '../../../common/utils/image';

const linkStyle = {
  color: 'inherit',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderColor: 'primary',
  paddingBottom: 2,
  textDecoration: 'none',
  fontSize: 'inherit'
};

const widgetsWithCenteredText = ['content4', 'introduction4', 'payment1', 'payment2'];

const standardCardLayouts = ['about1', 'content2'];

class Theme26 extends FixedWidth {
  static config = themeConfig;

  static get displayName() {
    return 'Theme26';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);

    if (widgetPreset === 'about1') {
      return {
        ...defaultProps,
        about1ImageAspectRatio: LANDSCAPE_ASPECT_RATIO
      };
    }

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        phoneOnSecondaryPage: true,
        useSocialLinks: true,
        hasLogoAlign: true,
        headerTreatmentsConfig: {
          headerTreatments: [FILL, FIT, INSET],
          defaultHeaderTreatment: INSET,
          imageTreatments: {
            [FILL]: 'none',
            [FIT]: 'none',
            [INSET]: 'category-solid'
          }
        },
        renderParallaxToggle: false
      }
      : defaultProps;
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...(!widgetsWithCenteredText.includes(widgetPreset) && { alignmentOption: 'left' }),
      ...(widgetType === 'HEADER' && { logoAlign: 'center' })
    };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      typographyOverrides: {
        LogoAlpha: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingDelta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'mlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingEpsilon: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingZeta: props => ({
          ...mergeTypographyOverrides.call(this, 'BodyAlpha', props)
        }),
        DetailsAlpha: {
          style: {
            font: 'alternate',
            color: 'lowContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        LinkBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'inherit',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        SubNavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavBeta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        SubNavBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        ButtonAlpha: props => {
          const { size = 'default' } = props;
          const fontSizes = {
            small: {
              fontSize: 'medium'
            },
            default: {
              fontSize: 'large'
            },
            large: {
              fontSize: 'large'
            }
          };
          return {
            style: {
              font: 'primary',
              fontWeight: 'normal',
              letterSpacing: 'normal',
              textTransform: 'none',
              ...fontSizes[size]
            }
          };
        }
      }
    };
  }

  Heading(props) {
    const { level, tag } = props;
    const typography = {
      2: 'HeadingEpsilon',
      5: 'DetailsAlpha'
    }[levelFilter({ tag, level })];
    return super.Heading(this.merge({ typography }, props));
  }

  Link({ style = {}, ...props }) {
    if (this.base.widgetType === 'SHOP' || this.base.widgetType === 'ZILLOW') {
      return super.Link({ style, ...props });
    }
    const { children } = props;
    const newStyle =
      children && typeof children !== 'string' ? { textDecoration: 'none' } : linkStyle;
    // eslint-disable-next-line no-unused-vars
    const { textDecoration, ...originalStyle } = style;
    return super.Link(this.merge({ style: newStyle }, { style: originalStyle, ...props }));
  }

  UtilitiesMenu(props) {
    const { hasNav } = props;
    return super.UtilitiesMenu(
      this.merge(
        {
          style: {
            '@md': {
              '> :first-child': {
                marginLeft: hasNav ? 'small' : '0'
              }
            }
          }
        },
        props
      )
    );
  }

  UtilitiesMenuLink(props) {
    return super.UtilitiesMenuLink(
      this.merge(
        {
          style: {
            borderBottomWidth: 0
          }
        },
        props
      )
    );
  }

  LinkContent(props) {
    return super.LinkContent(
      this.merge(
        {
          style: {
            textDecoration: 'none'
          },
          typography: 'LinkBeta'
        },
        props
      )
    );
  }

  Icon({ icon, ...props }) {
    icon = minimalIconMapping[icon] || icon;
    return super.Icon(
      this.merge(
        {
          icon,
          iconPack: { ...filledIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Blink.apply(this, [props]);
  }

  ContentCardImageThumbnail(props) {
    const standardCardLayoutsWidths = {
      about1: {
        ['@md']: {
          width: ABOUT1_IMAGE_WIDTH
        },
        ['@lg']: {
          width: '100%'
        }
      },
      content2: {
        ['@md']: {
          width: CONTENT2_IMAGE_WIDTH
        },
        ['@lg']: {
          width: '100%'
        }
      }
    };

    const { widgetPreset } = this.base;
    const style = standardCardLayouts.includes(widgetPreset)
      ? {
        height: 'auto',
        ...standardCardLayoutsWidths[widgetPreset]
      }
      : {};

    // Need to delete the height set by about widget which assumes
    // all about1 images are square
    if (widgetPreset === 'about1') {
      delete props.imageData.outputHeight;
    }

    return super.ContentCardImageThumbnail(this.merge({ style }, props));
  }

  ContentCardHeading(props) {
    return super.ContentCardHeading(
      this.merge(props, {
        style: {
          textAlign: 'inherit',
          alignSelf: 'inherit'
        }
      })
    );
  }

  ContentCardButton(props) {
    return super.ContentCardButton(
      this.merge(props, {
        style: {
          alignSelf: 'inherit'
        }
      })
    );
  }

  ImageThumbnail(props) {
    const { widgetPreset } = this.base;
    const style = {
      borderRadius: 0,
      width: '100%'
    };

    if (widgetPreset === 'about1') {
      const { imageData = {} } = props;
      const { outputWidth, outputHeight, imageDimension } = imageData;
      if (!imageDimension && !outputHeight && outputWidth) {
        imageData.outputHeight = outputWidth / LANDSCAPE_ASPECT_RATIO;
      }
      return super.Image(this.merge({ style }, { ...props, imageData }));
    }

    return super.ImageThumbnail(this.merge({ style }, props));
  }

  ContentCardImage(props) {
    return super.Image(
      this.merge(props, {
        style: {
          width: '100%'
        }
      })
    );
  }

  ContentText(props) {
    return super.ContentText(
      this.merge(
        {
          style: {
            'lineHeight': 1.8,
            'a:not([data-ux])': linkStyle
          },
          typography: 'BodyAlpha'
        },
        props
      )
    );
  }

  Text(props) {
    return super.Text(
      this.merge(
        {
          style: {
            'lineHeight': 1.8,
            'a:not([data-ux])': linkStyle
          }
        },
        props
      )
    );
  }

  ContentCardText(props) {
    return super.ContentCardText(
      this.merge(
        {
          style: {
            'a:not([data-ux])': linkStyle
          }
        },
        props
      )
    );
  }

  /* Header */

  LogoHeading(props) {
    return super.LogoHeading(
      this.merge(
        {
          style: {
            paddingVertical: 'medium'
          }
        },
        props
      )
    );
  }

  NavFooterLink(props) {
    return super.NavFooterLink(
      this.merge(props, {
        style: {
          'borderBottomStyle': 'solid',
          'borderBottomWidth': '1px',
          'borderColor': 'primary',
          'padding': 2,
          'marginHorizontal': 'small',
          'marginBottom': 'xxsmall',
          '@xs-only': {
            padding: 2
          }
        },
        typography: 'LinkBeta'
      })
    );
  }

  SectionHeading(props) {
    return super.SectionHeading(
      this.merge(
        {
          sectionHeadingHR: 'NONE',
          style: {
            textAlign: 'center'
          },
          typography: 'HeadingBeta'
        },
        props
      )
    );
  }

  Hero(props) {
    return super.Hero(
      this.merge(
        {
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: 'medium',
            paddingBottom: 'xlarge',
            width: '100%',
            ['@md']: {
              paddingVertical: 'xxlarge'
            }
          }
        },
        props
      )
    );
  }

  HeroRight(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            alignItems: 'flex-end'
          }
        },
        props
      )
    );
  }

  HeroLeft(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            alignItems: 'flex-start'
          }
        },
        props
      )
    );
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          typography: 'HeadingZeta'
        },
        props
      )
    );
  }

  HeroRightHeading(props) {
    return this.HeroHeading(
      this.merge(
        {
          style: {
            textAlign: 'right'
          }
        },
        props
      )
    );
  }

  HeroLeftHeading(props) {
    return this.HeroHeading(
      this.merge(
        {
          style: {
            textAlign: 'left'
          }
        },
        props
      )
    );
  }

  HeroText(props) {
    return super.SubTagline(
      this.merge(
        {
          style: {
            'a': linkStyle,
            'width': '100%',
            'lineHeight': 1.5,
            '@md': {
              width: 'auto'
            }
          },
          typography: 'HeadingEpsilon'
        },
        props
      )
    );
  }

  PromoBanner(props) {
    return super.PromoBanner(
      this.merge(
        {
          style: {
            '@md': {
              paddingVertical: 'xsmall'
            }
          }
        },
        props
      )
    );
  }

  PromoBannerText(props) {
    return super.PromoBannerText(
      this.merge(
        {
          style: {
            lineHeight: 1.4
          }
        },
        props
      )
    );
  }

  NavigationDrawer(props) {
    return super.NavigationDrawer(
      this.merge(
        {
          category: 'neutral',
          section: 'alt',
          style: {
            backgroundColor: 'section',
            boxShadow: 'none !important',
            maxWidth: '100%',
            ['@md']: {
              maxWidth: 400
            }
          }
        },
        props
      )
    );
  }

  NavigationDrawerInputSearch(props) {
    return super.NavigationDrawerInputSearch(
      this.merge(
        {
          style: {
            backgroundColor: 'searchMobileGray'
          }
        },
        props
      )
    );
  }

  NavigationDrawerContainer(props) {
    return super.Container(
      this.merge(props, {
        style: {
          'top': 40,
          '@md': {
            paddingTop: 'xxxlarge'
          }
        }
      })
    );
  }

  NavigationDrawerLink(props) {
    return super.NavigationDrawerLink(
      this.merge(
        {
          style: {
            'paddingLeft': 'xlarge',
            'borderBottomWidth': 0,
            'lineHeight': 1.2,
            '@md': {
              paddingLeft: 'xxlarge'
            },
            '> span': {
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderColor: 'transparent',
              paddingBottom: 2
            },
            ':hover > span': {
              borderColor: 'primary'
            }
          }
        },
        props
      )
    );
  }

  NavigationDrawerLinkActive(props) {
    return super.NavigationDrawerLinkActive(
      this.merge(
        {
          style: {
            '> span': {
              borderColor: '!primary'
            },
            ['@xs-only']: {
              fontWeight: '400'
            }
          }
        },
        props
      )
    );
  }

  NavigationDrawerSubLink(props) {
    return super.NavigationDrawerSubLink(
      this.merge(
        {
          style: {
            'paddingLeft': 'xxlarge',
            '@md': {
              paddingLeft: 'xxxlarge'
            }
          }
        },
        props
      )
    );
  }

  NavigationDrawerSubLinkActive(props) {
    return super.NavigationDrawerSubLinkActive(
      this.merge(
        {
          style: {
            '> span': {
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderColor: 'primary'
            }
          }
        },
        props
      )
    );
  }

  NavigationDrawerListItem(props) {
    return super.NavigationDrawerListItem(
      this.merge(
        {
          style: {
            borderBottomWidth: 0
          }
        },
        props
      )
    );
  }

  NavigationDrawerCloseIcon(props) {
    const { logoAlign } = props;
    return super.CloseIcon(
      this.merge(props, {
        style: {
          right: 'initial',
          top: 20,
          left: 20,
          zIndex: 2,
          ['@md']: {
            top: 80,
            left: logoAlign === 'center' ? 40 : 'unset',
            right: logoAlign === 'center' ? 'unset' : 40
          }
        }
      })
    );
  }

  MembershipHeading(props) {
    return super.MembershipHeading(
      this.merge(
        {
          style: {
            paddingHorizontal: 'xlarge'
          }
        },
        props
      )
    );
  }

  SocialLinksDrawer(props) {
    return this.SocialLinks(
      this.merge(
        {
          style: {
            '@md': {
              display: 'none'
            },
            'marginTop': 'medium',
            'paddingHorizontal': 'xlarge',
            'width': '100%',
            '> div': {
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              marginBottom: -5
            }
          }
        },
        props
      )
    );
  }

  SocialLinks(props) {
    return super.SocialLinks(
      this.merge(
        {
          style: {
            '@md': {
              'display': 'flex',
              'justifyContent': 'flex-end',
              '> div': {
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: -5
              }
            }
          }
        },
        props
      )
    );
  }

  SocialLinksLink(props) {
    return super.SocialLinksLink(
      this.merge(
        {
          style: {
            'border': 0,
            'marginBottom': 'xxsmall',
            ':first-child': {
              marginLeft: 0
            }
          }
        },
        props
      )
    );
  }

  GoogleTranslate(props) {
    return super.GoogleTranslate(
      this.merge(
        {
          style: {
            '@xs': {
              left: 'initial',
              right: 20
            }
          }
        },
        props
      )
    );
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            backgroundColor: 'sectionUltraLowContrast',
            height: '0.8em'
          }
        },
        props
      )
    );
  }

  Input(props) {
    return super.Input(
      this.merge(
        {
          style: {
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: '1px !important',
            paddingTop: 23,
            paddingBottom: 7,
            paddingHorizontal: 'xsmall',
            backgroundColor: 'transparent',
            borderBottomStyle: 'solid',
            borderColor: 'section',
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  InputTextArea(props) {
    return super.InputTextArea(
      this.merge(
        {
          style: {
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderStyle: 'solid',
            paddingTop: 'xsmall',
            WebkitAppearance: 'none !important'
          }
        },
        props
      )
    );
  }

  UtilitiesMenuIcon(props) {
    if (props['data-aid'] === DataAid.SEARCH_ICON_RENDERED_OPEN) {
      return super.UtilitiesMenuIcon(this.merge({ category: 'neutral' }, props));
    }
    return super.UtilitiesMenuIcon(props);
  }

  InputSearch(props) {
    return super.InputSearch(
      this.merge(
        {
          style: {
            backgroundColor: 'white'
          },
          category: 'neutral'
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    const isSubscribe2 =
      this.base.widgetType === 'SUBSCRIBE' && this.base.widgetPreset === 'subscribe2';
    return super.InputFloatLabelLabel(
      this.merge(
        {
          style: {
            left: 'xsmall',
            top: isSubscribe2 ? '30%' : '50%'
          }
        },
        props
      )
    );
  }

  InputFloatLabelInput(props) {
    const isSubscribe2 =
      this.base.widgetType === 'SUBSCRIBE' && this.base.widgetPreset === 'subscribe2';
    return super.InputFloatLabelInput(
      this.merge(
        {
          style: {
            ':focus + label': {
              top: isSubscribe2 ? -5 : 5
            }
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        {
          style: {
            // Theme Based
            borderWidth: 'xsmall',
            borderRadius: 0,
            // Static
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(props, {
        style: {
          paddingVertical: 'small',
          paddingHorizontal: 'small'
        }
      })
    );
  }

  ContentBasic(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    return super.ContentBasic(this.merge(overrides, props));
  }

  ContentCard(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    return super.ContentCard(this.merge(overrides, props));
  }

  // Card Banner styling
  CardBannerContainer(props) {
    return super.CardBannerContainer(
      this.merge(
        {
          style: {
            '@md': {
              flexDirection: 'column'
            }
          }
        },
        props
      )
    );
  }

  CardBannerHeading(props) {
    return super.CardBannerHeading(
      this.merge(
        {
          style: {
            paddingBottom: 'medium'
          }
        },
        props
      )
    );
  }

  CardBannerBlock(props) {
    return super.CardBannerBlock(
      this.merge(
        {
          style: {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
          }
        },
        props
      )
    );
  }

  // 50/50
  SplitItem(props) {
    const { widgetType } = this.base;

    if (widgetType === 'CONTACT' || widgetType === 'SUBSCRIBE') {
      return super.SplitItem(props);
    }

    return super.SplitItem(
      this.merge(
        {
          style: {
            '@md': {
              'flexBasis': '34%',
              'maxWidth': '34%',
              '> *': {
                maxWidth: '100%'
              }
            }
          }
        },
        props
      )
    );
  }

  SplitItemImage(props) {
    const { widgetType } = this.base;

    if (widgetType === 'CONTACT' || widgetType === 'SUBSCRIBE') {
      return super.SplitItemImage(props);
    }

    return super.SplitItemImage(
      this.merge(
        {
          style: {
            '@md': {
              flexBasis: '66%',
              maxWidth: '66%'
            }
          }
        },
        props
      )
    );
  }

  Phone(props) {
    return super.Phone(
      this.merge(
        {
          style: {
            a: {
              borderBottomWidth: '0 !important',
              paddingBottom: 0
            }
          },
          typography: 'HeadingDelta'
        },
        props
      )
    );
  }

  HeadingMajor(props) {
    return super.HeadingMajor(this.merge({ typography: 'HeadingEpsilon' }, props));
  }

  CardHeading(props) {
    return super.CardHeading(this.merge(props, { typography: 'HeadingGamma' }));
  }

  HeadingProduct(props) {
    return super.HeadingProduct(this.merge(props, { typography: 'HeadingGamma' }));
  }

  ContentHeading(props) {
    return super.ContentHeading(this.merge({ typography: 'HeadingDelta' }, props));
  }

  FooterLink(props) {
    return super.FooterLink(this.merge({ typography: 'LinkBeta' }, props));
  }

  ContentBigText(props) {
    return super.ContentBigText(this.merge({ typography: 'BodyAlpha' }, props));
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'center' }, props));
  }

  EmbedVideo({ isVerticalVideo, ...props }) {
    if (isVerticalVideo) {
      return super.EmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.EmbedVideo(props);
  }

  WrapperInsetEmbedVideo({ isVerticalVideo, ...props }) {
    if (isVerticalVideo) {
      return super.WrapperInsetEmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.WrapperInsetEmbedVideo(props);
  }
}

export default Theme26;
